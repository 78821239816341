<template>
	<div class="fitnessplanCreate">

		<!-- EXERCISE FILLED -->
		<div v-if="selectedFitnessPlanActivity" class="fitnessplanCreate__exercise">
			<div class="fitnessplanCreate__exercise__item">

				<div v-if="selectedFitnessPlanActivity && activityType == 'workout.WorkoutPage'">
					<WorkoutItem :workout="selectedFitnessPlanActivity" :selectOnly="true"></WorkoutItem>
				</div>
				<div v-else-if="selectedFitnessPlanActivity && activityType == 'exercise.PlaylistPage'">
					<PlaylistItem :playlist="selectedFitnessPlanActivity" :selectOnly="true"></PlaylistItem>
				</div>
				<div v-else-if="selectedFitnessPlanActivity" class="card">
					<div v-if="selectedFitnessPlanActivity.name" class="card__title">{{ selectedFitnessPlanActivity.name }}</div>
					<div v-else-if="selectedFitnessPlanActivity.title" class="card__title">{{ selectedFitnessPlanActivity.title }}</div>
					<div class="card__subtitle">{{ selectedFitnessPlanActivity.subtitle }}</div>
					<img v-if="selectedFitnessPlanActivity.image_url" class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + selectedFitnessPlanActivity.image_url" alt="" />
                    <img v-else-if="activityType == 'blog.BlogPage'" class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + selectedFitnessPlanActivity.main_image_url" alt="" />
					<img v-else-if="activityType == 'Custom/UserPlaylist'" class="card__image" src="https://app.sportlerplus.com/scaled/800/bruce-mars-gJtDg6WfMlQ-unsplash.jpg" alt="" >
				</div>

			</div>
			<div v-if="!uniqueChallengeId" class="fitnessplanCreate__exercise__footer" @click="selectActivity">
				<img src="@/assets/ic_16_fitnessplan_change.svg">
				Übung ändern
			</div>
		</div>

		<!-- EXERCISE EMPTY -->
		<div v-else class="fitnessplanCreate__exercise fitnessplanCreate__exercise--empty" @click="selectActivity">
			<img src="@/assets/ic_fitnessplan_add.svg">
			Übung wählen
		</div>

		<!-- SERIENTERMIN -->
		<div v-if="!relatedFitnessPlanItem && !uniqueChallengeId" class="list list--background">

			<label :class="repeatActivity ? 'list__item is-active' : 'list__item'">
				<div class="list__item__icon">
					<img src="@/assets/ic_24_fitnessplan_serie.svg" alt="" />
				</div>
				<div class="list__item__content">
					<div class="list__item__label">
						Serientermin
					</div>
				</div>
				<div class="toggle">
                    <input type="checkbox" v-model="repeatActivity">
                    <span class="toggle__button"></span>
                </div>
			</label>

			<div v-if="repeatActivity" class="fitnessplanCreate__serie">
				<radio-set v-model="selectedRepeatInterval" label-name="" :options="repeatOptions"/>
			</div>
		</div>

		<!-- DATE & TIME -->
		<div class="list list--background">

			<!-- DATE -->
			<div v-if="!uniqueChallengeId" class="list__item drilldown">
				<div class="list__item__icon">
					<img src="@/assets/ic_24_fitnessplan_date.svg" alt="" />
				</div>
				<label class="list__item__content">
					<div v-if="!repeatActivity" class="list__item__label">
						Datum
					</div>
					<div v-if="repeatActivity" class="list__item__label">
						Startdatum
					</div>
					<div class="list__item__value list__item__value--light">
						<input type="date" v-model="activityDate">
					</div>
				</label>
			</div>

			<div v-if="repeatActivity" class="list__item drilldown">
				<div class="list__item__icon">
					<img src="@/assets/ic_24_fitnessplan_date.svg" alt="" />
				</div>
				<label class="list__item__content">
					<div class="list__item__label">
						Enddatum
					</div>
					<div class="list__item__value list__item__value--light">
						<input type="date" v-model="activityEndDate">
					</div>
				</label>
			</div>

			<!-- DAY -->
			<!-- <div v-if="repeatActivity" class="list__item drilldown">
				<div class="list__item__icon">
					<img src="@/assets/ic_24_fitnessplan_date.svg" alt="" />
				</div>
				<label class="list__item__content">
					<div class="list__item__label">
						Tag
					</div>
					<div class="list__item__value list__item__value--light">
						<select v-model="weekday" name="weekday" dir="rtl">
							<option value=1>Montag</option>
							<option value=2>Dienstag</option>
							<option value=3>Mittwoch</option>
							<option value=4>Donnerstag</option>
							<option value=5>Freitag</option>
							<option value=6>Samstag</option>
							<option value=0>Sonntag</option>
						</select>
					</div>
				</label>
			</div> -->

			<!-- TIME -->
			<div class="list__item drilldown">
				<div class="list__item__icon">
					<img src="@/assets/ic_24_fitnessplan_time.svg" alt="" />
				</div>
				<div class="list__item__content">
					<div class="list__item__label">
						Uhrzeit
					</div>
					<div class="list__item__value list__item__value--light">
						<input type="time" v-model="activityTime" style="width: 100%">
					</div>
                </div>
			</div>

		</div>

		<v-btn :disabled="!selectedFitnessPlanActivity" @click="finaliseEvent" class="button button--primary">{{ finaliseButtonTitle }}</v-btn>
	</div>
</template>

<script>
import RadioSet from "@/components/RadioSet";
import WorkoutItem from "@/components/WorkoutItem.vue";
import PlaylistItem from '@/components/PlaylistItem.vue';
import { generateGuid } from '@/sportlerplus-helper';

export default {
	name: 'FitnessplanCreate',
	components: { RadioSet, WorkoutItem, PlaylistItem },
	data() {
		return {
			repeatActivity: true,
			//weekday: 1,
			repeatOptions: [
                { key: "Jede Woche", value: 0 },
				{ key: "Jede 2. Woche", value: 1 },
				{ key: "Monatlich", value: 2 },
			],
			selectedRepeatInterval: null,
			activityDate: null,
			activityEndDate: null,
			activityTime: "18:00",
			fitnessPlan: {
				items: [],
			},
			editItemId: null,
            relatedFitnessPlanItem: null,
            uniqueChallengeId: null,
		}
	},
	methods: {
		init() {
			this.editItemId = this.$route.query.guid;

            this.relatedFitnessPlanItem = this.$route.query.related_guid;

			this.$store.dispatch('fetchFitnessPlan').then( (res) => {
				if (res.length != 0) {
					this.fitnessPlan = res[0];
				}
			});

			this.activityDate = window.localStorage.getItem('activityDate') || this.$route.query.initialDate || new Date().toISOString().split('T')[0];
			this.activityEndDate = window.localStorage.getItem('activityEndDate');
			this.activityTime = window.localStorage.getItem('activityTime') || "18:00";
			this.selectedRepeatInterval = this.repeatOptions[window.localStorage.getItem('selectedRepeatInterval') || 0];
            if (window.localStorage.getItem('uniqueChallengeId')) {
                this.uniqueChallengeId = window.localStorage.getItem('uniqueChallengeId');
                window.localStorage.removeItem('uniqueChallengeId');
            }
			if (window.localStorage.getItem('repeatActivity')) {
				this.repeatActivity = window.localStorage.getItem('repeatActivity') === "true";
			}
		},
		selectActivity() {
			this.$router.push("/fitnessplan/create/exercises");
		},
		finaliseEvent() {
			this.$store.commit('addRecentFitnessPlanActivity', Object.assign({}, this.selectedFitnessPlanActivity));
			if (this.editItemId) {
				let event = this.fitnessPlan.items.find(fpi => fpi.guid == this.editItemId);
				if (event == null) return;
					Object.assign(event, {
					weekday: null,
					repeating: this.repeatActivity,
					repeatInterval: this.selectedRepeatInterval ? this.selectedRepeatInterval.value : null,
					activityTime: this.activityTime,
					activityDate: this.activityDate,
					activityEndDate: this.activityEndDate,
					activity: Object.assign({}, this.selectedFitnessPlanActivity),
					activityState: null,
				});
			}
			else {
                if(this.relatedFitnessPlanItem) {
                    let oldFpi = this.fitnessPlan.items.find(fpi => fpi.guid === this.relatedFitnessPlanItem);
                    oldFpi.exceptionDates = oldFpi.exceptionDates || [];
                    oldFpi.exceptionDates.push(window.localStorage.getItem('originalActivityDate'));
                }
				let event = {
					weekday: null,
					repeating: this.repeatActivity,
					repeatInterval: this.selectedRepeatInterval ? this.selectedRepeatInterval.value : null,
					activityTime: this.activityTime,
					activityDate: this.activityDate,
					activityEndDate: this.activityEndDate,
					activity: Object.assign({}, this.selectedFitnessPlanActivity),
					guid: generateGuid(),
					activityState: null,
				};

				this.fitnessPlan.items.push(event);
			}
			this.$store.dispatch('saveFitnessPlan', this.fitnessPlan).then( () => {
				this.$router.go(-1);
			});
		},
		clearLocalStorage() {
			this.$store.commit('setSelectedFitnessPlanActivity', null);
			window.localStorage.removeItem('activityDate');
			window.localStorage.removeItem('activityEndDate');
			window.localStorage.removeItem('activityTime');
			window.localStorage.removeItem('selectedRepeatInterval');
			window.localStorage.removeItem('repeatActivity');
		}
	},
	computed: {
		selectedFitnessPlanActivity() {
			return this.$store.getters.getSelectedFitnessPlanActivity;
		},
		activityType() {
			if (this.selectedFitnessPlanActivity == null) { return null; }
			if (this.selectedFitnessPlanActivity.meta === undefined) { return "Category"; }
			else { return this.selectedFitnessPlanActivity.meta.type; }
		},
		finaliseButtonTitle() {
			if (this.editItemId) { return "Änderungen speichern"; }
			return "Training erstellen";
		}
    },
	mounted() {
		this.init();
	},
	/* eslint-disable no-unused-vars */
	beforeRouteLeave (to, from, next) {
		// called when the route that renders this component is about to
		// be navigated away from.
		// has access to `this` component instance.

		if (to.path == "/fitnessplan/create/exercises") {
			// persist vars to localstorage
			window.localStorage.setItem('activityDate', this.activityDate);
			if (this.activityEndDate) {
				window.localStorage.setItem('activityEndDate', this.activityEndDate);
			}
			window.localStorage.setItem('activityTime', this.activityTime);
			window.localStorage.setItem('selectedRepeatInterval', this.selectedRepeatInterval.value);
			window.localStorage.setItem('repeatActivity', this.repeatActivity);
		}
		else {
			this.clearLocalStorage();
		}
		next();
	}
}
</script>

<style lang="scss">
	.fitnessplanCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.button {
			margin-top: auto;
		}

		&__exercise {
			display: flex;
			flex-direction: column;
			background: rgba($colorBlack, .04);
			border-radius: 8px;
			overflow: hidden;
			margin-bottom: 16px;

			&--empty {
				font-size: 14px;
				font-weight: 700;
				letter-spacing: -0.21px;
				color: rgba($colorBlack, .24);
				text-transform: uppercase;
				padding: 64px 0 48px;
				text-align: center;
				align-items: center;
				justify-content: center;

				img {
					opacity: .24;
					margin-bottom: 11px;
				}
			}

			&__item {
				width: 100%;

				.card, .workout__item {
					border-radius: 4px 4px 0 0;
					margin-bottom: 0!important;
				}
			}

			&__footer {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
				letter-spacing: -0.21px;
				background: $colorPrimary;
				color: $colorWhite;
				padding: 12px 0;

				img {
					margin-right: 6px;
				}
			}
		}

		&__serie {
			div {
				display: flex;
				justify-content: space-between;
				margin-top: -8px;
			}

			button {
				font-size: 11px;
				font-weight: 700;
				letter-spacing: -0.23px;
				background: rgba($colorBlack, .08);
				color: rgba($colorBlack, .48);
				border-radius: 2px;
				padding: 6px 0;
				transition: all .2s ease-in-out;
				flex: auto;
				margin: 0 12px !important;

				&:first-of-type, &:last-of-type {
					margin: 0 !important;
				}

				&.is-active {
					box-shadow: inset 0 0 0 1px $colorPrimary, 0 0 0 1px $colorPrimary;
					background: rgba($colorPrimary, .24);
					color: $colorPrimary;
				}
			}
		}

		.is-active {
			box-shadow: none;
		}

		.list {
			margin-bottom: 16px;

			&__item__label {
				font-size: 17px;
			}

			input[type="date"], input[type="time"], select {
				min-height: inherit;
				color: rgba($colorBlack, .48);
				font-size: 14px;
				line-height: 20px;
				opacity: 1;
				text-align: right;
				display: flex;
				justify-content: center;

				&::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
					color: rgba($colorBlack, .48);
				}

				&::-webkit-date-and-time-value{
					margin-right: 0 !important;
				}
			}

			input[type="time"] {
				&:after {
					content: 'Uhr';
					margin-left: 4px;
				}
			}
		}
	}
</style>